.room-list-item {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;

  width: 100%;
  padding: 15px;
  margin: 5px 0;
  //background: fade(rgba(210, 210, 210, 0.71), 10%);
  border-radius: 7.5px;

  .room-image {
    padding-right: 15px;
  }

  .info {
    width: 70%;

    .room-name {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .latest-message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .status {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;

    .unread {
      color: @primary-color;
      font-weight: bold;
    }
  }
}

.room-list-item-selected {
  background: fade(@primary-color, 10%);
}

@primary-color: #001e2e;@primary-1: rgba(0, 30, 46, 0.25);@link-color: #5c0011;@success-color: #001e2e;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
0 9px 28px 8px rgba(0, 0, 0, 0.05);